import format from 'date-fns/format'

// Date strings from Strapi in format yyyy-mm-dd, but also works if already
// a full UTC string i.e. yyyy-mm-ddTHH:mm:ss:SSSZ
export const getUTCDate = (dateString?: string): Date => {
  const date = dateString ? new Date(dateString) : new Date()
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

// Converts e.g. 2023-04-24 -> 24th Apr '23
export const getFormattedShortDate = (dateString: string): string => {
  const utcDate = getUTCDate(dateString)
  // Necessary to do in two bits to get apostrophe in between.
  return `${format(utcDate, 'do MMM')} '${format(utcDate, 'yy')}`
}

// Converts e.g. 2023-04-24 -> April 24th, 2023
export const getFormattedLongDate = (dateString: string): string => {
  const utcDate = getUTCDate(dateString)
  return format(utcDate, 'MMMM do, yyyy')
}

// Replaces markdown highlight tags `==text==` with HTML `<mark>text</mark>`
export const replaceMarkdownHighlightWithHTML = (text: string | undefined): string =>
  text ? text.replace(/==([^=]+)==/g, '<mark>$1</mark>') : ''
